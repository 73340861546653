$svg-icon-path: "images/";

@import "~sbf-design-system/scss/base";

/**
 * CONTENTS
 *
 * SETTINGS
 * Modifiers.............Modifications to global styles
 *
 * TOOLS
 * Mixins................Useful page-specific mixins
 *
 * BASE
 * Typography............Basic modification to global typography
 * Grid..................Different types of grids used on this page
 * Buttons...............So many button styles on this page
 *
 * OBJECTS
 * Wrappers..............Wrapping and constraining elements
 *
 * COMPONENTS
 * Interactive...........Styles for special functionality
 * Page-sections.........Page-specific section styles
 *
 * TRUMPS
 * CMS content-editor......Dark outline
 */

/**********************
 * SETTINGS - Modifications to global styles
 ***********************/

$custom-breakpoint: 960px;
$custom-medium: 775px;

.old-header,
header {
  margin-bottom: 0;
}

footer {
  // I'm not sure why this is needed... But it is
  overflow: hidden;
}

.sans-serif {
  font-family: $sansSerifFamily;
}

.sb-hr {
  color: #B4CAD7;
  height: 32px;
  margin: 24px auto;
}

.inline-form {
  position: relative;
  max-width: 360px;
  width: 100%;
}

.inline-input {
  @include sbf-inline-input;

  width: 100%;
  border: 0;

  .donation-amount-wrapper {
    @include sbf-donate-amount;

    width: 100%;
  }

  &.currency-usd input {
    border: 0;
    font-size: 30px;
    color: $sbf-blue;
    font-weight: 700;
  }
}

.inline-input.donation {
  padding: 12px 0 16px 0;
}

.inline-form .tk-button:nth-child(2) {
  margin-right: 0;
}

.inline-form .tk-button {
  letter-spacing: -0.5px;
  flex: 1 0 calc(50% - 6px);
  width: calc(50% - 6px);
  min-width: 0;
  margin: 8px 12px 16px 0;

  @media (min-width: $custom-medium) {
    margin: 10px 33px 16px 0;
    flex-basis: calc(50% - 24px);
  }
}

@media (max-width: $custom-medium - 1) {
  .inline-form .button--monthly {
    margin-right: 0;
  }
}

.form--one-line {
  input {
    background: white;
    border: 0;
  }

  .tk-button {
    flex: 1;
    display: block;
    width: 100%;
    margin-right: 0;

    @media (min-width: $custom-medium) {
      width: 100%;
      margin-right: 12px;
      margin-left: 0;
    }
  }

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;

    .inline-input {
      flex: 1 0 200px;
    }

    .tk-button {
      flex: 1 0 100px;
      width: auto;
    }
  }
}

.inline-buttons {
  display: flex;
}

.inline-element {
  display: inline;
}

.last-item {
  margin-bottom: 0;
}

/**********************
 * TOOLS - Useful page-specific mixins
 ***********************/

/**********************
 * BASE - Overarching page-specific changes
 ***********************/

body {
  background: none;
  max-width: 100%;
  overflow-x: hidden;
}

.inner img {
  max-width: 100%;
}

/* TYPOGRAPHY */

p {
  font-family: $serifFamily;
}

a {
  border-bottom: none;
}

.homepage-lg-header {
  font-size: 2em;
  line-height: 1.25;
  margin-bottom: 2px;
  letter-spacing: -0.035em;

  @media (min-width: $custom-medium) {
    font-size: 2.7em;
  }
}

.lg-header--narrow {
  max-width: 600px;
}

.homepage-sm-header {
  font-size: 1.4em;
  margin-top: 12px;
  margin-bottom: 8px;
  line-height: 1.4;
  letter-spacing: -0.015em;

  @media (min-width: $custom-medium) {
    font-size: 1.5em;
  }
}

.homepage-subheader {
  @include sbf-heading($name: 'headline-24-serif');

  margin-bottom: 0;

  @media (min-width: $large) {
    @include sbf-heading($name: 'headline-40-serif');
  }

  color: $sbf-black;
}

.link-black {
  color: $sbf-black;

  &:hover,
  &:active {
    color: lighten($sbf-black, 25%);
  }
}

.link--yellow {
  color: $sbf-dark-yellow;

  &:hover,
  &:active {
    color: lighten($sbf-dark-yellow, 25%);
  }
}

/* GRID */

.tile-grid__tiles h2 {
  z-index: 5;
  line-height: 1.1;
  margin-bottom: 4px;
  width: 430px;
  max-width: 100%;

  @media (min-width: $small) {
    margin-bottom: 0;
  }

  @media (min-width: $custom-medium) {
    margin-left: auto;
  }
}

.tile-grid__tiles p {
  font-size: 1em;
  width: 320px;
  max-width: 100%;
  margin: 0 auto 0 0;
  margin-bottom: 0;
  z-index: 5;

  @media (min-width: $small) {
    margin: 0 0 0 auto;
  }
}

.tile-grid {
  @media (min-width: $small) {
    display: flex;
  }
}

.tile-grid .icon {
  top: 5px;
  left: -5px;
  height: 24px;
  width: 24px;

  @media (min-width: $small) {
    display: none;
  }
}

.tile-grid a {
  &:hover,
  &:focus,
  &:active {
    color: white;
    border-color: white;
  }
}

.tile-grid__wrap {
  flex-wrap: wrap;
}

.top-grid {
  > .flex-1,
  > .flex-2 {
    min-height: 300px;
  }
}

.flex-1 {
  flex: 1;
  width: 100%;
}

.flex-2 {
  flex: 1;
  width: 100%;
}

@media (min-width: $small) {
  .top-grid {
    flex-wrap: wrap;
  }

  .top-grid > .flex-1 {
    flex-basis: 100%;
  }

  .tile-grid__horizontal.tile-grid__horizontal {
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    min-height: 600px;

    > * {
      min-height: 300px;
    }
  }

  .tile-grid__vertical {
    flex-direction: column;
    height: 600px;
  }

  .tile-grid__vertical .flex-1,
  .tile-grid__vertical .flex-2 {
    width: 50%;
  }

  .flex-1 {
    flex: 1;
    flex-basis: 300px;
    min-height: 0;
  }

  .flex-2 {
    flex: 2;
    flex-basis: 600px;
    min-height: 0;
  }
}

@media (min-width: $custom-breakpoint) {
  .top-grid {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

/* BUTTONS */

.tk-button {
  text-align: center;

  @include sbf-button($btnType: "sbf-blue");
}

.tk-button--yellow {
  @include sbf-button($btnType: "sbf-yellow");

  color: $sbf-black;

  &:focus,
  &:hover {
    color: $sbf-black;
  }
}

.button--peach {
  @include sbf-button($btnType: "sbf-peach");
}

.button--orange {
  @include sbf-button($btnType: "sbf-orange");
}

.button--pink {
  @include sbf-button($btnType: "sbf-pink");
}

.button--purple {
  @include sbf-button($btnType: "sbf-purple");
}

.button--light-blue {
  @include sbf-button($btnType: "sbf-light-blue");
}

.button--green {
  @include sbf-button($btnType: "sbf-dark-green");
}

.button--slategray {
  @include sbf-button($btnType: "sbf-blue");

  background: #445C67;
  border-color: #445C67;

  &:focus,
  &:hover,
  &:active {
    background: darken(#445C67, 15%);
  }
}

/**********************
 * OBJECTS
 ***********************/

/* WRAPPERS */

.split-section {
  @media (min-width: $custom-medium) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

.split__copy,
.split__image {
  flex: 1;

  @media (min-width: $custom-medium) {
    min-height: 400px;
    width: 50%;
  }
}

@media (min-width: $custom-medium) {
  .split__copy {
    > * {
      max-width: 620px;
      margin-left: auto;
    }
  }

  .split__right {
    > * {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

@media (min-width: $large) {
  .split__copy > p {
    font-size: 1.3em;
  }
}

.video-container {
  margin: auto;
  max-width: $large;
  height: 100%;
}

.responsive-video {
  position: absolute;
  width: 100%;
  transform: translateY(30%);
}

.hover-cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: $custom-breakpoint) {
    flex-direction: row;
    justify-content: center;
  }
}

.card {
  flex: 0 1 400px;
  height: 320px;
  max-height: 375px;
  max-width: 500px;
  background: $sbf-dark-yellow url('event.png') top center no-repeat;
  background-size: 110%;
  color: white;
  margin: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: background 100ms linear;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }

  @media (min-width: $custom-breakpoint) {
    flex-basis: 29%;
    max-width: 350px;
  }
}

.card__1 {
  background-image: url('//www.stbaldricks.org/file/website_content_images/homepage-spotlights/battle-homepage.jpg');
}

.card__2 {
  background-image: url('//www.stbaldricks.org/file/website_content_images/homepage-spotlights/5-reasons-to-start-an-event.jpg');
}

.card__3 {
  background-image: url('//www.stbaldricks.org/file/website_content_images/homepage-spotlights/summer-fellow-homepage.jpg');
}

.card__header {
  background-color: $sbf-dark-yellow;
  padding: 12px 12px 0;
  font-size: 1.35em;
  letter-spacing: -0.015em;
  line-height: 1.1;
  padding-bottom: 4px;
  margin: 0;
}

.card__copy {
  font-family: $serifFamily;
  background-color: $sbf-dark-yellow;
  padding: 0 12px;
  font-size: 1em;
  margin-bottom: 0;
  padding-bottom: 12px;
}

.article-card {
  @include border;
  @include shadowed;

  display: block;
  background: white;
  padding: 12px;
  padding-right: 24px;
  margin: 24px auto;
  max-width: 550px;
  text-align: left;
  transition: background 100ms linear;

  &::after {
    content: '';
    border-top: 2px solid #CCC;
    border-right: 2px solid #CCC;
    transform: translateY(-50%) rotate(45deg);
    height: 12px;
    width: 12px;
    position: absolute;
    top: 50%;
    right: 12px;
  }

  &:hover {
    background: #EEE;
  }

  @media (min-width: $large) {
    padding: 16px;
    padding-right: 44px;
    margin: 24px 0 24px auto;

    &::after {
      right: 24px;
    }
  }

  @media (min-width: $x-large) {
    margin: 24px 24px 24px auto;
  }
}

.article__img {
  margin-right: 12px;
  width: 75px;
  height: 75px;
  flex: 0 0 75px;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }

  @media (min-width: $large) {
    margin-right: 16px;
  }
}

.article__header {
  color: $sbf-black;
  margin: 0;
  font-size: 1.1em;
  line-height: 1.35;

  @media (min-width: $large) {

  }
}

.article__copy {
  font-family: $sansSerifFamily;
  font-size: 0.75em;
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
}

.flag {
  display: flex;
  align-items: center;
}

.flag__content {
  margin-top: -5px;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  margin: 0;
  z-index: -1;
}

/**********************
 * COMPONENTS
 ***********************/

/* INTERACTIVE */

.hoverelem {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  z-index: 2;
  top: 0;
  left: 0;

  @media (min-width: $small) {
    left: -100%;
  }

  .head-shaving & {
    background: $sbf-mint-green;
  }

  .advocacy & {
    background: $sbf-light-purple;
  }

  .kids-are-gold & {
    background: $sbf-peach;
    display: none;

    @media (min-width: $custom-medium) {
      display: block;
    }
  }

  .families & {
    background: $sbf-light-blue;
  }

  .research & {
    background: $sbf-dark-green;
  }

  .childhood-cancer & {
    background: $sbf-pink;
  }
}

.gold-block {
  background: $sbf-dark-yellow;
  padding: 12px;
  width: 100%;
  z-index: 10;
}

.map-dots {
  z-index: 1;
  pointer-events: none;
  display: none;

  @media (min-width: $x-large) {
    display: block;
  }
}

.animate .dot {
  animation: zoom 10s linear 1s infinite;
}

.dot {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  background: $sbf-blue;
  border: 5px solid rgba($sbf-light-blue, 0.8);
  background-clip: padding-box;
  border-radius: 50%;
  opacity: 0;

  &.dot:nth-child(1) {
    top: 45px;
    left: 120px;
  }

  &.dot:nth-child(2) {
    top: 170px;
    left: 450px;
    animation-delay: 300ms;
  }

  &.dot:nth-child(3) {
    top: 230px;
    left: 190px;
    animation-delay: 800ms;
  }

  &.dot:nth-child(4) {
    top: 240px;
    left: 340px;
    animation-delay: 1200ms;
  }

  &.dot:nth-child(5) {
    top: 30px;
    left: 640px;
    animation-delay: 1800ms;
  }

  &.dot:nth-child(6) {
    top: 420px;
    left: 170px;
    animation-delay: 2500ms;
  }

  &.dot:nth-child(7) {
    top: 540px;
    left: 300px;
    animation-delay: 2900ms;
  }

  &.dot:nth-child(8) {
    top: 310px;
    left: 90px;
    animation-delay: 3200ms;
  }

  &.dot:nth-child(9) {
    top: 390px;
    left: 1270px;
    animation-delay: 3500ms;
  }
}

@keyframes zoom {
  5%,
  95% {
    opacity: 0;
    transform: scale(0.1);
    animation-timing-function: cubic-bezier(0.24, 0.54, 0.8, 1.95);
  }

  11%,
  85% {
    opacity: 1;
    transform: scale(1);
    animation-timing-function: linear;
  }
}

.hidden {
  display: none;
}

/* PAGE SECTIONS */
.user-signed-out {
  .hero-section {
    background-position-y: 45px;

    @media (min-width: $medium) {
      background-position-y: top;
    }

    @media (min-width: 2000px) {
      background-position-y: -75px;
    }
  }
}

.user-signed-in {
  .hero-section--cta {
    margin: -45px 0 0 0;

    @media (min-width: 420px) {
      margin: 0;
    }
  }
}

.hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #CDCAC1;
  min-height: 280px;
  color: white;
  text-align: center;
  border-bottom: 2px solid white;
  overflow-x: hidden;
  position: relative;
  padding: 0 16px;

  @media (min-width: $small) {
    min-height: 520px;
  }

  @media (min-width: $medium) {
    background-position-y: top;
  }

  @media (min-width: $large) {
    background-position: bottom;
    min-height: 646px;
  }

  @media (min-width: 2000px) {
    background-position-y: -75px;
  }

  .section-container {
    display: none;

    @media (min-width: $large) {
      max-width: 1320px;
      margin: 38px auto 32px auto;
      padding: 0 0 0 10px;
      display: block;
    }

    @media (min-width: $x-large) {
      padding: 0;
    }
  }

  .content-container {
    text-align: center;
    background-color: $sbf-blue;
    border-radius: 3px;

    @media (min-width: 600px) {
      max-width: 480px;
      margin: 0 auto;
    }

    @media (min-width: $large) {
      padding: 48px 60px 36px 60px;
      margin: 0;
    }

    h1 {
      @include sbf-heading('headline-40-sans');

      @media (min-width: $large) {
        @include sbf-heading('headline-60-sans');

        padding: 0 10px;
      }
    }

    h2 {
      @include sbf-heading('headline-24-sans');
    }

    p {
      @include sbf-body('small-serif');

      line-height: 1.8;
      margin: 0 0 16px 0;
    }
  }

  .child-details {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  .sbf-ce-section {
    outline: #DDD dotted 1px !important;
  }
}

.hero-section--cta {
  display: block;
  margin: -30px 0 0 0;

  @media (min-width: 420px) {
    margin: 0;
  }

  @media (min-width: 600px) {
    margin: -35px 0 0 0;
  }

  @media (min-width: $large) {
    display: none;
    margin: 0;
  }

  .child-details {
    text-align: center;
    padding: 4px;
  }

  .content-container {
    background-color: $sbf-blue;
    text-align: center;
    color: #FFF;
    padding: 14px 26px 20px 26px;

    h1 {
      @include sbf-heading('headline-24-sans');

      @media (min-width: $small) {
        @include sbf-heading('headline-30-sans');
      }
    }

    h2 {
      @include sbf-heading('headline-18-sans');
    }

    h1,
    h2 {
      margin: 0 0 8px 0;
    }

    p {
      max-width: 340px;
      margin: 0 auto 12px auto;
    }

    .sb-hr {
      max-width: 300px;
      margin: 10px auto;
      height: 24px;
    }
  }
}

.child-details {
  color: $sbf-black;
  text-align: left;
  background-color: $sbf-white;

  @media (min-width: $large) {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px 12px;
  }

  p:nth-of-type(1) {
    @include sbf-body('default-sans');

    display: inline;
    font-weight: 600;
  }

  p:nth-of-type(2) {
    @include sbf-body('small-sans');

    display: inline;
    margin: 0 0 0 4px;
    color: darken($sbf-grey, 35%);
  }

  p:nth-of-type(3) {
    @include sbf-body('small-serif');

    margin: 0;
  }
}

.head-shaving {
  background: url('researchers-abroad.jpg');
  background-size: cover;
}

.advocacy {
  background: url('take-action.jpg');
  background-size: cover;
}

.head-shaving,
.advocacy {
  max-height: 300px;
}

.families {
  background: url('family.jpg');
  background-size: cover;
  background-position: center top;
}

.research {
  background: url('dr-arceci-opt.jpg');
  background-size: cover;
  background-position: center top;
}

.families,
.research {
  @media (min-width: $small) {
    // IE flexbugs haxxx
    width: 50%;
    flex-basis: auto;
  }
}

.childhood-cancer {
  background: url('background-opt.jpg') no-repeat;
  background-size: cover;
}

.head-shaving,
.advocacy,
.kids-are-gold,
.families,
.research,
.childhood-cancer {
  // Flexbox here is just to align the text to the bottom
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  color: white;
  padding: 12px;
  text-align: left;
  border: 2px solid white;
  overflow: hidden;
  height: 299px;
  min-height: 300px;

  @media (min-width: $small) {
    height: auto;
    min-height: 0;
    text-align: right;
    align-items: flex-end;
  }
}

.kids-are-gold {
  background: url('shavee-d-opt.jpg') top center;
  background-size: cover;
  min-height: 520px;
  max-height: none;

  @media (min-width: $custom-medium) {
    order: 1;
    min-height: 0;
  }
}

.no-pad {
  padding: 0;
}

.video-section {
  background: url('poster-m.jpg') center;
  background-size: cover;
  text-align: center;
  padding: 75px 8px;
  color: white;

  p {
    max-width: 850px;
    margin: 16px auto 24px;
  }

  > * {
    max-width: 720px;
    margin: 0 auto;
  }

  @media (min-width: $custom-medium) {
    background: rgba($sbf-black, 0.8);
    padding: 150px 24px;

    p {
      font-size: 1.3em;
    }
  }
}

.video-button.video-button {
  font-size: 0.8em;
  font-family: $sansSerifFamily;
  text-transform: uppercase;
  letter-spacing: 0.21em;
  line-height: $baseLineHeight;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  margin: 24px auto 12px;

  &::before {
    content: '';
    width: 54px;
    height: 38px;
    background: url('play_button.png');
    margin: 8px auto;
    vertical-align: middle;
    display: block;

    @media (min-width: $large) {
      margin: 0 12px 4px 0;
      display: inline-block;
    }
  }
}

.ambassadors {
  color: white;
  background: $sbf-light-blue;

  @media (min-width: $custom-medium) {
    border-top: 4px solid white;
    border-bottom: 4px solid white;
  }
}

.ambassadors__copy,
.ambassadors__image {
  padding: 64px 24px;
}

.ambassadors__copy {
  order: 1;
  text-align: center;

  @media (min-width: $custom-medium) {
    border-right: 4px solid white;
    order: 0;
    text-align: left;
  }
}

@media (min-width: $custom-medium) {
  .ambassadors__image {
    background: url('real-cures.jpg') center top no-repeat;
    background-size: cover;
  }
}

.ambassadors__image {
  display: none;

  @media (min-width: $custom-medium) {
    display: block;
  }
}

.funding {
  padding: 64px 24px 250px;
  background: $sbf-light-green url('map-opt.jpg') no-repeat bottom left;
  background-size: cover;
  color: white;
  justify-content: flex-end;
  overflow: hidden;

  @media (min-width: $x-large) {
    padding: 80px 8px 160px;
    background-position: top left;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 150%;
    z-index: 5;
    background: url('girl-opt.png') bottom right no-repeat;

    @media (min-width: $small) {
      width: 100%;
    }
  }
}

.funding__image {
  display: none;
}

.funding__copy {
  text-align: center;
  z-index: 15;
  margin: 0 auto;
  width: 100%;
  max-width: 620px;
  min-height: 460px;
  flex-basis: auto;

  .homepage-lg-header {
    margin-bottom: 16px;
  }

  @media (min-width: $custom-medium) {
    min-height: 400px;
  }

  @media (min-width: $large) {
    p {
      font-size: 1.3em;
    }
  }

  @media (min-width: $x-large) {
    text-align: left;
    width: 50%;
    max-width: none;
    margin: 0 0 0 50%;
    padding: 0 24px 148px 0;

    .homepage-lg-header {
      margin-bottom: 8px;
    }
  }
}

.get-involved {
  background: white;
  text-align: center;
  padding: 48px 0;

  .homepage-lg-header {
    margin-bottom: 24px;
  }
}

.involved__emphasis {
  max-width: 780px;
  font-size: 1.2em;
  font-style: italic;
  margin: 32px auto 12px;
  padding: 0 12px;

  @media (min-width: $small) {
    font-size: 1.3em;
    max-width: 500px;
  }

  @media (min-width: $custom-medium) {
    font-size: 1.4em;
    max-width: 810px;
  }
}

.raised-banner {
  background: $sbf-black;
  color: white;
  padding: 24px 8px;

  @media (min-width: $small) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (min-width: $custom-medium) {
    flex-wrap: nowrap;
  }
}

.logomark {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('logomark.svg');
  background-size: 30px auto;
  background-position: 0 -210px;
  top: 5px;
}

.raised-header {
  font-family: $serifFamily;
  text-transform: uppercase;
  order: -1;
  flex-basis: 100%;
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  @media (min-width: $custom-medium) {
    order: 2;
    flex-basis: auto;
  }
}

.this-year,
.last-year {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.75em;
  margin-top: 4px;
  margin-left: -12px;

  @media (min-width: $small) {
    flex-direction: row;
    margin-left: 0;
  }

  @media (min-width: $custom-medium) {
    margin-top: 0;
    font-size: 1em;
  }
}

@media (min-width: $small) {
  .this-year {
    .raised-year {
      order: 2;
    }
  }
}

.last-year {
  order: 3;
}

.raised-year {
  color: $sbf-dark-yellow;
  font-weight: bold;
  padding: 0 12px;
}

.raised-amount {
  font-size: 1.5em;
  font-weight: bold;
}

.newsletter {
  border-top: 4px solid white;
  border-bottom: 4px solid white;
}

.newsletter__left {
  background: linear-gradient(rgba($sbf-dark-green, 1), rgba($sbf-light-blue, 1));
  color: white;
  text-align: center;
  padding: 64px 16px;

  @media (min-width: $custom-medium) {
    background: linear-gradient(rgba($sbf-dark-green, 0.8), rgba($sbf-light-blue, 0.8));
    padding: 42px 36px;
    border-right: 4px solid white;
  }

  p > a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.newsletter__subhead {
  font-size: 1.4em;
  letter-spacing: -0.015em;

  @media (max-width: $small - 1) {
    margin-bottom: 36px;
  }
}

.newsletter__right {
  background: $sbf-white url('newsletter.jpg') top center no-repeat;
  background-size: cover;
  text-align: center;
  padding: 80vw 32px 40px;
  color: $sbf-white;

  > * {
    max-width: 490px;
    margin: 0 auto 8px;
  }

  > p {
    font-size: 1.1em;
    margin-top: 10px;
    margin-bottom: 8px;
    padding: 0 8px;
  }

  @media (min-width: 600px) {
    padding: 46vw 32px 40px;
  }

  @media (min-width: $custom-medium) {
    padding: 32vw 24px 64px;
  }

  @media (min-width: $large) {
    padding: 280px 64px 64px;
  }

  @media (min-width: 1252px) {
    padding-top: 320px; // Keeps the left and right boxes from growing too tall
  }

  @media (min-width: $x-large) {
    > p {
      font-size: 1.25em;
      margin-top: 12px;
    }

    > * {
      max-width: 600px;
    }
  }
}

.major-gifts {
  background: #EFEDF2 url('homepage-cta-t-m.jpg') center bottom no-repeat;
  color: $sbf-black;
  text-align: center;

  @media (min-width: $custom-medium) {
    background: #EFEDF2 url('homepage-cta-d-opt.jpg') center top no-repeat;
    background-size: cover;
    text-align: left;
    padding-bottom: 200px;
    color: $sbf-white;
  }
}

.gifts__copy {
  padding: 64px 8px 560px;
  max-width: 580px;
  margin: 0 auto;
  flex: none;

  .homepage-lg-header {
    line-height: 1.1;
  }

  @media (min-width: $custom-medium) {
    padding: 80px 16px 40px;
    margin: 0 auto 0 0;
  }
}

.gifts__image {
  display: none;
  flex: none;

  @media (min-width: $custom-medium) {
    display: block;
  }
}

.background-video {
  position: fixed;
  left: -25%;
  width: auto;
  z-index: -1;

  @media (min-width: $custom-medium + 20px) {
    left: 0;
  }
}

.sponsor-footer {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 12px;
  width: 100%;
  text-align: center;

  @media (min-width: 390px) {
    height: 50px;
  }

  @media (min-width: $custom-medium) {
    display: flex;
    align-items: center;
    width: auto;
    bottom: 64px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -50px;
      border-right: 30px solid rgba(0, 0, 0, 0.6);
      border-top: 50px solid transparent;
      width: 20px;
      height: 0;
    }
  }
}

.sponsor__header {
  font-size: 0.8rem;
  display: block;
  margin: 0;
  margin-bottom: 10px;

  @media (min-width: 390px) {
    display: inline-block;
    margin-bottom: 0;
  }

  @media (min-width: $custom-medium) {
    font-size: 1rem;

    &::after {
      content: ' • ';
      font-size: 0.7rem;
      vertical-align: middle;
      padding: 0 2px;
    }
  }

  + .logo-sportsclips {
    margin-left: 5px;
  }
}

.sponsor__logo {
  vertical-align: middle;
  margin: 0 2px;

  @media (min-width: $custom-medium) {
    transform: none;
  }
}

/**********************
 * TRUMPS - CMS Content-Editor Dark outline
 ***********************/

.get-involved,
.newsletter__right,
.child-details {
  .sbf-ce-section {
    outline: #333 dotted 1px !important;
  }
}

// iPad fix for donation field
// Overriding the 760 bp from the forms.scss
.inline-input.donation::before {
  left: 12px;

  @media (min-width: $custom-medium) {
    left: 26px;
  }
}

// TP-94791 - Hide header background image editable section because it is only editable in Springboard.
div[data-sbf-cename="Header Background Image"] {
  display: none;
}
